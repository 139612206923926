import { Button } from '@iheartradio/web.accomplice/button';
import { Check, Plus } from '@iheartradio/web.accomplice/icons';

export interface FollowButtonProps {
  isFollowing?: boolean;
  onFollow?: () => void;
  onUnfollow?: () => void;
}

export function FollowButton({
  isFollowing,
  onFollow,
  onUnfollow,
}: FollowButtonProps) {
  return isFollowing ?
      <Button
        color={{ dark: 'white', light: 'gray' }}
        inline={false}
        kind="primary"
        onPress={onUnfollow}
        type="submit"
      >
        <Check size={16} /> Following
      </Button>
    : <Button
        color={{ dark: 'white', light: 'gray' }}
        inline={false}
        kind="secondary"
        onPress={onFollow}
        type="submit"
      >
        <Plus size={16} /> Follow
      </Button>;
}
